<template>
  <v-container  fluid style="margin-top:60px;margin-bottom: 75px;">
   
      
      
    <v-row fluid>
        <v-col cols="12">
            <center><b >Статистика нарахувань за <br>торгові точки мережі</b></center>
        </v-col>
    </v-row>
    <v-card class="simple_card" style="margin-top: 35px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 109% */
text-align: center;color: #4B3C32;padding: 10px;">
        
          <b ><font style="color:green">Підтверджено</font></b><br>
                      <b ><font style="color:#2f49ff">Очікує підтвердження</font></b><br>
                      <b ><font style="color:gray">Відсутній користувач</font></b><br>
                      <div class="photo"></div> — фото вітрини <br>
                      <div class="dmx "></div> — сканування запасу <br>
                      <div class="test"></div>  — тести <br>
                      <div class="stock"></div>  — збільшення запасу <br>
                      <div class="photocode"></div>  — збільшення запасу <br>
                      <font-awesome-icon icon="fa fa-square " class="icon-wrong"  aria-hidden="true"/> — помилка виконання / не виконувалось <br>
                      <font-awesome-icon icon="fa fa-square " class="icon-correct"  aria-hidden="true"/> — виконано вірно <br>
            
    </v-card>




    
  <v-card class="simple_card" style="margin-top: 35px;">
    <div style="display:flex;justify-content: space-between; padding: 15px 15px 5px 15px;"> 
      <div>Період: </div> 
      <div>{{detail_h}} </div>
      <div class="calendar" @click="calendar_show=true"></div>
    </div>
    <hr style="margin-left: 13px;
    margin-right: 13px;">
  </v-card>


  <v-dialog v-model="calendar_show" style="">
      <v-card class="simple_card">
        <v-card-title class="headline lighten-2" style="margin-bottom:-10px;justify-content:center;">
          <!-- header -->
          Обери період  
          <div @click="reset_pass_dialog = false" class="calendar_show"></div>
        </v-card-title>
       
        <v-card-text>
          <v-row  fluid align="center"
        justify="center">
      <v-col cols="12" align="center"
        justify="center">
    <v-date-picker
      
        v-model="date"
        :allowed-dates="allowed_dates_m"
        type="month" 
        class="mt-0 no-title"
        :min="pars_min_date"
        :max="pars_max_date"
         :month-format="getMonthFormat"
        :title-date-format="getTitleDateFormat"
        color="orange lighten-1"
      ></v-date-picker>
      </v-col>
    </v-row>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="calendar_show = false">   Закрити</span>
        </v-card-actions> -->
        
      </v-card>
     <div class="popup_bottom_robot"></div>
    </v-dialog>





    <v-card  style="margin-top: 15px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* identical to box height, or 109% */
text-align: center;color: #4B3C32;padding: 10px;" class="simple_card" v-for="(statm,i) in stat" :key="i">
      <v-row  style="border-bottom: 1px solid grey;"
              
              >
                <v-col cols="6"><center>Точка</center></v-col>
                <v-col cols="3"><center>%</center></v-col>
                <v-col cols="3"><center>UAH</center></v-col>
      </v-row>
      <v-container
            class="ma-0 pa-0 mb-3"
            v-for="(cd,b) in statm.c_d"
                :key="b"
               :style="'color:'+c_state[cd.state]" >
              <v-row
             
              >
                <v-col cols="6" style="font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
display: flex;
align-items: center;

color: #4B3C32;

">{{cd.name}}</v-col>
                <v-col cols="3" style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
text-align: center;
color: #4B3C32;

"><center>{{cd.res_percent}}</center></v-col>
                <v-col cols="3" style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 29px;
text-align: center;

color: #F07300;

"><center>{{cd.res_summ}}</center></v-col>
              
              
              </v-row>  
              <v-row  class="mt-n4">
                <v-col cols="6" style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 25px;
/* or 53% */
display: flex;
align-items: center;

color: #4B3C32;

">
                  Перший період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                
                  <div style="display: flex;justify-content: space-evenly;">
                    <div class="photo" :class="cd.exec_result&&cd.exec_result.photo1==1?'correct':'wrong'"></div>
                    <div class="dmx" :class="cd.exec_result&&cd.exec_result.sku1==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t11==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t12==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t13==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t14==1?'correct':'wrong'" ></div>
                    <div class="stock" :class="cd.exec_result&&cd.exec_result.dmx1==1?'correct':'wrong'" ></div> 

<!-- 
                    <font-awesome-icon icon="fa fa-camera " :class="cd.exec_result&&cd.exec_result.photo1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-warehouse " :class="cd.exec_result&&cd.exec_result.sku1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t11==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t12==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t13==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t14==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-qrcode " :class="cd.exec_result&&cd.exec_result.dmx1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/> -->
                  
                  </div>                  
                </v-col>
              </v-row>
              <v-row  class="mt-n6 mb-0">
                <v-col cols="6" style="
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 19px;
line-height: 25px;
/* or 53% */
display: flex;
align-items: center;

color: #4B3C32;

">
                  Другий період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                                  
                  <div style="display: flex;justify-content: space-evenly;">
                    <div class="photo" :class="cd.exec_result&&cd.exec_result.photo2==1?'correct':'wrong'"></div>
                    <div class="dmx" :class="cd.exec_result&&cd.exec_result.sku2==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t21==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t22==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t23==1?'correct':'wrong'" ></div>
                    <div class="test" :class="cd.exec_result&&cd.exec_result.t24==1?'correct':'wrong'" ></div>
                    <div class="stock" :class="cd.exec_result&&cd.exec_result.dmx2==1?'correct':'wrong'" ></div>      
                    
                  </div>
                </v-col>
              </v-row>
              <hr>
            </v-container>


    </v-card>



<!-- 
    <v-row fluid>
        <v-col cols="12">
              <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
        v-model="stat_model"
      >
       <v-expansion-panel
          v-for="(statm,i) in stat"
          :key="i"
          
        >
          <v-expansion-panel-header  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;"><b>{{statm.month}}</b></v-expansion-panel-header>
          <v-expansion-panel-content style="padding:15px;" >
              <v-row
              
              >
                <v-col cols="6"><b>Точка</b></v-col>
                <v-col cols="3"><center><b>%</b></center></v-col>
                <v-col cols="3"><center><b>UAH</b></center></v-col>
              </v-row>
            <v-container
            class="ma-0 pa-0 mb-3"
            v-for="(cd,b) in statm.c_d"
                :key="b"
               :style="'color:'+c_state[cd.state]" >
              <v-row
             
              >
                <v-col cols="6">{{cd.name}}</v-col>
                <v-col cols="3"><center>{{cd.res_percent}}</center></v-col>
                <v-col cols="3"><center>{{cd.res_summ}}</center></v-col>
              
              
              </v-row>  
              <v-row  class="mt-n4">
                <v-col cols="6">
                  Перший період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                
                  <div style="display: flex;"><font-awesome-icon icon="fa fa-camera " :class="cd.exec_result&&cd.exec_result.photo1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-warehouse " :class="cd.exec_result&&cd.exec_result.sku1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t11==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t12==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t13==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t14==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-qrcode " :class="cd.exec_result&&cd.exec_result.dmx1==1?'icon-correct':'icon-wrong'" aria-hidden="true"/></div>                  
                </v-col>
              </v-row>
              <v-row  class="mt-n6 mb-0">
                <v-col cols="6">
                  Другий період:&nbsp;
                </v-col>
                <v-col cols="6" class="pt-4">                                  
                  <div style="display: flex;"><font-awesome-icon icon="fa fa-camera " :class="cd.exec_result&&cd.exec_result.photo2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-warehouse " :class="cd.exec_result&&cd.exec_result.sku2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t21==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t22==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t23==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-question-circle " :class="cd.exec_result&&cd.exec_result.t24==1?'icon-correct':'icon-wrong'" aria-hidden="true"/>&nbsp;
                    <font-awesome-icon icon="fa fa-qrcode " :class="cd.exec_result&&cd.exec_result.dmx2==1?'icon-correct':'icon-wrong'" aria-hidden="true"/></div>              
                </v-col>
              </v-row>
              <hr>
            </v-container>

          </v-expansion-panel-content>
        </v-expansion-panel>
              </v-expansion-panels>
      </v-col>
    </v-row> -->

      
      

 
      <v-dialog v-model="faq">
      <v-card>
        <v-card-title class=""  style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_faq')}}  
        </v-card-title>
       
        <v-card-text>
          <v-expansion-panels
        :accordion="accordion"
        :popout="popout"
        :inset="inset"
        :multiple="multiple"
        :focusable="focusable"
        :disabled="disabled"
        :readonly="readonly"
        :flat="flat"
        :hover="hover"
        :tile="tile"
      >
        <v-expansion-panel
          v-for="(faq,i) in faqs"
          :key="i"
        >
          <v-expansion-panel-header><b>{{faq.header}}</b></v-expansion-panel-header>
          <v-expansion-panel-content v-html="faq.text" style="padding:15px;">
            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="faq = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_write')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="write_tp">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_tp"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
</template>

<script>
import btnrnd from "../components/btnrnd.vue";
// eslint-disable-next-line no-unused-vars
import axios from "axios";
// eslint-disable-next-line no-unused-vars
import { API_LOCATION } from "../../config";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    btnrnd,
  },
  data: () => ({
    faq:false,
    write:false,
    write_tp:false,
    contact:false,
    c_state:{"-1":'gray',"1":"green","0":"#2f49ff"},
    stat:[
        // {month:'Вересень 2021',c_d:[{name:'Кривий Ріг,	Горняцький мкр 44',res:'90',state:'1'},
        //                             {name:'Кривий Ріг,	Спаська 20',res:'60',state:'-1'},
        //                             {name:'Кривий Ріг,	Волгоградская 4',res:'50',state:'0'},
        //                             {name:'Кривий Ріг,	Покровська вул. 32',res:'78',state:'1'},
        //                             {name:'Кривий Ріг,	Медична вул. 19',res:'20',state:'0'},
        //                             {name:'Кривий Ріг,	200-річчя Кривого Рогу вул. 30А',res:'30',state:'1'},]}
    ],
   
        accordion: false,
    popout: false,
    inset: false,
    multiple: true,
    disabled: false,
    readonly: false,
    focusable: false,
    flat: false,
    hover: false,
    tile: false, 
    stat_model:[0],
    req_tp:'',
    req_tp_tp:'',
        apiurl:API_LOCATION,
        calendar_show:false,
    date: '',
    allowed_dates_arr:[],
    pars_min_date:'',
    pars_max_date:'',
    detail:false,
    detail_h:'',
    month_lang:{
        
        'ua':["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"]
      },
      
    own_list:[],
    own_list_history:[],

  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    },
      title() {
        return this.$t('title')
      },
      faqs(){
        
        return[
          
          {'header':this.$t('q1'),'text':this.$t('a1')},
          {'header':this.$t('q2'),'text':this.$t('a2')},
          {'header':this.$t('q3'),'text':this.$t('a3')},
          {'header':this.$t('q4'),'text':this.$t('a4')},
          {'header':this.$t('q5'),'text':this.$t('a5')},
          {'header':this.$t('q6'),'text':this.$t('a6')},
          {'header':this.$t('q7'),'text':this.$t('a7')},
          {'header':this.$t('q8'),'text':this.$t('a8')},
          {'header':this.$t('q9'),'text':this.$t('a9')},
          {'header':this.$t('q10'),'text':this.$t('a10')}
        ]},
  },
  watch:{
    date:function(){
        this.calendar_show=false
          console.log("sel_date:"+this.date);
          this.lpr_stat(this.date.split('-')[0],this.date.split('-')[1]);
      },

      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {
    axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "get_stat_month",
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars
        console.log(resp.data);
        var counter=0;
        resp.data.forEach((element) => {
          this.allowed_dates_arr.push( element.year+'-'+element.month)
          counter++;
          if(counter===1){ this.pars_max_date=element.year+'-'+element.month; } else {this.pars_min_date=element.year+'-'+element.month}
          
          this.own_list.push({
            month: element.month,
            year: element.year,
            
          });

          
        });
        console.log(this.allowed_dates_arr);
        console.log('@@@@@@@@@@@@@@@@@@@@@@@@@');
        
        
      });
     
  },
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    //this.lpr_stat(new Date().getFullYear(),(new Date().getMonth() + 1));
    this.lpr_stat(new Date().getFullYear(),(new Date().getMonth() - 2));
    
  },
  methods:{


    
    getYearsRange(start, end) {
      const years = [];
      for (let year = start; year <= end; year++) {
        years.push(year);
      }
      return years;
    }
  ,

    getMonthFormat: function(isoDate) {
      var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    }, 
    getTitleDateFormat: function(isoDate) {
       var month=["Січень","Лютий","Березень","Квітень","Травень","Червень","Липень","Серпень","Вересень","Жовтень","Листопад","Грудень"];
       
      //console.log(parseInt(isoDate.split('-')[1]));
      return month[parseInt(isoDate.split('-')[1])-1];
    },

    allowed_dates_m: function(val){
        return this.allowed_dates_arr.includes(val)
    }
    ,
        save_request: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create', 'question':this.req_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write=false;
            this.req_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

        save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },

      lpr_stat: function(year,month){
      axios
      .post(
        API_LOCATION,
        {
          data: "component_data",
          action: "lpr_stat2",
          year: year,
          month: month,
          
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
        // eslint-disable-next-line no-unused-vars

        this.detail_h=this.month_lang[this.$i18n.locale][month-1]+"  "+year;
        console.log(resp.data);
        
        this.stat=resp.data;
        
      });
    }

    }
};
</script>


<i18n>
{
  
  "ua":{
      "title":"Статистика",
      "btn1":"Надіслати повідомлення <br>до служби підтримки",
      "btn2":"Відповіді на часті питання",
      "btn3":"Надіслати повідомлення <br>торговому представнику",
      "close":"Закрити",
      "h_write":"Надіслати повідомлення до служби підтримки",
      "h_contact":"Надіслати повідомлення торговому представнику",
      "h_faq":"Відповіді на часті питання",
      "q1":"Чи можу я змінити номер телефону для участі у програмі?",
      "a1":"Так, це можливо. Зверніться, будь ласка, до Вашого Торгового Представника.",

      "q2":"Яку максимальну кількість балів я можу отримати за місяць?",
      "a2":"Максимальна кількість балів, яку можна отримати, залежить від типу Вашого контракту: <br>К300 – 300 балів, <br>К400 – 400 балів,<br>К500 – 500 балів. <br>Виконання екстра завдань дає можливість заробити додаткові бали в  доповнення до стандартної кількості балів контракту.",
      
      "q3":"Я зробив(-ла) помилку при виборі асортименту для участі. Чи можу я його змінити?",
      "a3":"Зміна асортименту для участі доступна раз на один квартал. Для уточнення інформації зверніться, будь ласка, до Вашого Торгового Представника.",

      "q4":"Чи зберігаються на наступний місяць мої накопичені бали?",
      "a4":"Так, зберігаються.",

      "q5":"Мені не нарахували бали за завдання «Фото вітрини» та «Мій товарний запас», у чому причина?",
      "a5":"Усі фото перевіряються вручну модератором, тому модерація може займати декілька днів. Точну інформацію Ви можете дізнатись у свого Торгового Представника.",

      "q6":"Які критерії враховуються при перевірці завдання «Фото вітрини»?",
      "a6":"Критеріями перевірки є  наявність усіх SKU, (відповідно до обраного асортименту) та правильність їх викладки.",

      "q7":"Якщо я беру участь в програмі як мережева торгова точка, скільки SKU має бути на фото вітрини?",
      "a7":"На фото вітрини має бути 32 SKU із актуального асортименту програми (актуальний асортимент уточнюйте у Вашого Торгового Представника).",

      "q8":"Які критерії враховуються при перевірці завдання «Мій товарний запас»?",
      "a8":"Критерієм перевірки є наявність на фото блоку кожного SKU із списку, наведеного у завданні, з нанесеним унікальним кодом.",

      "q9":"Я ввів(-ла) неправильний номер картки Fishka, чи можна його змінити?",
      "a9":"Так, змінити номер карти Fishka можна. Для цього достатньо звернутися у службу тех. підтримки iTeam.",

      "q10":"Як довго чекати на замовлені емоції?",
      "a10":"Поповнення мобільного рахунку та нарахування балів Fishka відбувається одразу (15-20 хвилин) автоматично, замовлені електронні сертифікати «Сільпо», «Єва» та «Розетка» з'являються у застосунку одразу."


      

  }
}
</i18n>
<style type="text/css">

.photo{
  background-image: url('/img/icostat/photo.png');
  height: 23px;
  width: 23px;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}


.dmx{
  background-image: url('/img/icostat/dmx.png');
  height: 23px;
  width: 23px;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}


.stock{
  background-image: url('/img/icostat/stock.png');
  height: 23px;
  width: 23px;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}



.photocode{
  background-image: url('/img/icostat/photocode.png');
  height: 23px;
  width: 23px;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}


.test{
  background-image: url('/img/icostat/test.png');
  height: 23px;
  width: 23px;
  background-size: 100% 100%;
  position: relative;
  display: inline-block;
}

.wrong::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Match circle shape */
  background-color: #9D1C2B40; /* Overlay color with opacity */
  pointer-events: none; /* Ensures it doesn't block interactions */
}


.correct::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Match circle shape */
  background-color: #008A5560; /* Overlay color with opacity */
  pointer-events: none; /* Ensures it doesn't block interactions */
}



.icon-correct {
  color: #008A55;
}
.icon-wrong {
  color: #9D1C2B;
}
  </style>
  
<style scoped>
.v-date-picker-title__year{
  display: unset !important;
  text-align: center !important;
}

.v-date-picker-title,.v-picker__title__btn{
  display: unset !important;
  text-align: center !important;
}

.or_b{
  color: #F07300;
}

.gr_b{
  color: #4B3C32;
}

.calendar{
  background-image: url(/img/calico.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}
::v-deep .no-title .v-picker__title {
  display: none; /* Hides the title */
}
</style>